import { createSlice } from '@reduxjs/toolkit';
import template from '../pages/home/templates/template.03';
import * as yup from 'yup';
const initialState = {
  drawer: {
    anchor: 'right',
    top: { content: null, open: false },
    left: { content: null, open: false },
    bottom: { content: null, open: false },
    right: { content: null, open: false },
  },
  errors: [
    {
      id: 1,
      message: 'test',
      type: 'warning',
      hasRead: false,
      hasShown: false,
    },
  ],
  modal: {
    component: () => null,
    open: false,
    props: {},
  },
  notifications: [
    {
      id: 1,
      message: 'test',
      type: 'success',
      hasRead: false,
      hasShown: false,
    },
  ],
  routes: [
    {
      name: 'Home',
      path: '/',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/',
          element: { component: 'Home', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'home',
      config: {
        activeSections: template,
      },
    },
    {
      name: 'Wallet',
      path: '/wallet',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/wallet',
          element: { component: 'Wallet', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'wallet',
      config: {
        activeSections: [{
          component: 'Box',
          props: {},
          content: 'Wallet Page.',
        }],
      },
    },
    {
      name: 'Sign In',
      path: '/sign-in',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/sign-in',
          element: { component: 'SignIn', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'sign-in',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            children: [
              {
                component: 'Form',
                props: {
                  initialValues: {
                    email: '',
                    password: '',
                  },
                  validationSchema: yup.object({
                    email: yup
                      .string()
                      .email('Invalid email')
                      .required('Email is required'),
                    password: yup.string().required('Password is required'),
                  }),
                },
                action: {
                  method: 'useForm',
                },
                onSubmit: {
                  type: 'post',
                  url: '/api/v1/authenticate',
                  onSuccess: {
                    useAuth: true,
                    type: 'redirect',
                    url: '/dashboard',
                  },
                  onError: {
                    type: 'notification',
                    message: 'Invalid email or password',
                    variant: 'error',
                  },
                },
                children: [
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h2',
                      sx: { fontFamily: 'Cookie' },
                    },
                    content: 'Welcome back!',
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'EmailTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'PasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },

                  {
                    component: 'Button',
                    props: {
                      variant: 'contained',
                      type: 'submit',
                      fullWidth: true,
                    },
                    content: 'Submit',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Register',
      path: '/register',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/register',
          element: { component: 'Register', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'register',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            children: [
              {
                component: 'Form',
                props: {
                  initialValues: {
                    email: '',
                    password: '',
                    'confirm-password': '',
                  },
                  validationSchema: yup.object({
                    email: yup
                      .string()
                      .email('Invalid email')
                      .required('Email is required'),
                    password: yup.string().required('Password is required'),
                    'confirm-password': yup
                      .string()
                      .oneOf(
                        [yup.ref('password'), null],
                        'Passwords must match'
                      )
                      .required('Confirm password is required'),
                  }),
                },
                action: {
                  method: 'useForm',
                },
                onSubmit: {
                  type: 'post',
                  url: '/api/v1/register',
                  onSuccess: {
                    useAuth: true,
                    type: 'redirect',
                    url: '/sign-in',
                  },
                  onError: {
                    type: 'notification',
                    message: 'Invalid email or password',
                    variant: 'error',
                  },
                },
                children: [
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h2',
                      sx: { fontFamily: 'Cookie' },
                    },
                    content: 'Welcome! Please register to continue.',
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'EmailTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'PasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },

                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: 'column',
                      marginY: 1,
                    },
                    children: [
                      {
                        component: 'ConfirmPasswordTextField',
                        props: {},
                        content: null,
                      },
                    ],
                  },
                  {
                    component: 'Button',
                    props: {
                      variant: 'contained',
                      type: 'submit',
                      fullWidth: true,
                    },
                    content: 'Submit',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Page',
      path: '/some-page',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/some-page',
          element: { component: 'Page', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'page',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Page Page.',
          },
        ],
      },
    },
    {
      name: 'Link',
      path: '/link',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/link',
          element: { component: 'Page', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'link',
      config: {
        activeSections: [
          {
            component: 'LinkSection',
            props: {},
            content: null,
          },
        ],
      },
    },
    {
      name: 'Multi Step Authentication',
      path: '/mfa',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/mfa',
          element: { component: 'MultiStepAuthentication' },
        },
      ],
      requiredAuth: true,
      key: 'mfa',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Multi Step Authentication Page.',
          },
        ],
      },
    },
    {
      name: 'Forgot Password',
      path: '/forgot-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/forgot-password',
          element: { component: 'ForgotPassword', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'forgot-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Forgot Password Page.',
          },
        ],
      },
    },
    {
      name: 'Reset Password',
      path: '/reset-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/reset-password',
          element: { component: 'ResetPassword', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'reset-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Reset Password Page.',
          },
        ],
      },
    },
    {
      name: 'Verify Email',
      path: '/verify-email',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/verify-email',
          element: { component: 'VerifyEmail', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'verify-email',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Verify Email Page.',
          },
        ],
      },
    },
    {
      name: 'Verify Phone Number',
      path: '/verify-phone-number',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/verify-phone-number',
          element: { component: 'VerifyPhoneNumber', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'verify-phone-number',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Verify Phone Number Page.',
          },
        ],
      },
    },
    {
      name: 'Change Password',
      path: '/change-password',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '/change-password',
          element: { component: 'ChangePassword', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'change-password',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Change Password Page.',
          },
        ],
      },
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      element: 'DashboardLayout',
      children: [
        {
          index: true,
          path: '/dashboard',
          element: { component: 'Dashboard', requiredAuth: true },
        },
      ],
      requiredAuth: true,
      key: 'dashboard',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {},
            content: 'Dashboard Page.',
          },
        ],
      },
    },
    {
      name: 'Not Found',
      path: '*',
      element: 'LandingLayout',
      children: [
        {
          index: true,
          path: '*',
          element: { component: 'NotFound', requiredAuth: false },
        },
      ],
      requiredAuth: false,
      key: 'not-found',
      config: {
        activeSections: [
          {
            component: 'Box',
            props: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            },
            content: 'Not Found Page.',
          },
        ],
      },
    },
  ],
  settings: {
    loading: false,
    language: 'en',
  },
  theme: {
    palette: {
      mode: 'light', // light or dark
    },
    direction: 'ltr', // ltr or rtl
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    toggleModal: (state, action) => {
      state.modal.open = !state.modal.open;
    },
    setModalComponent: (state, action) => {
      state.modal.component = action.payload;
    },
    setModalProps: (state, action) => {
      state.modal.props = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.drawer[action.payload.anchor].open =
        !state.drawer[action.payload.anchor].open;
    },
    setDrawerContent: (state, action) => {
      state.drawer[action.payload.anchor].content = action.payload.content;
    },
    setDrawerAnchor: (state, action) => {
      state.drawer.anchor = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setDrawer,
  setErrors,
  setModalComponent,
  setNotifications,
  setRoutes,
  setSettings,
  setTheme,
  toggleModal,
  setModalProps,
  toggleDrawer,
  setDrawerContent,
  setDrawerAnchor,
} = appSlice.actions;

export const appDrawerSelector = (state) => state.app.drawer;
export const appErrorsSelector = (state) => state.app.errors;
export const appModalSelector = (state) => state.app.modal;
export const appNotificationsSelector = (state) => state.app.notifications;
export const appRoutesSelector = (state) => state.app.routes;
export const appSelector = (state) => state.app;
export const appSettingsSelector = (state) => state.app.settings;
export const appThemeSelector = (state) => state.app.theme;

export const selectDrawers = (state) => state.app.drawer;
export const selectDrawer = (anchor) => (state) => state.app.drawer[anchor];
export const selectDrawerContent = (anchor) => (state) =>
  state.app.drawer[anchor].content;
export const selectDrawerOpen = (anchor) => (state) =>
  state.app.drawer[anchor].open;
export const selectDrawerAnchor = (state) => state.app.drawer.anchor;

export const selectModal = (state) => state.app.modal;
export const selectModalComponent = (state) => state.app.modal.component;
export const selectModalProps = (state) => state.app.modal.props;
export const selectModalOpen = (state) => state.app.modal.open;

export const selectRoutes = (state) => state.app.routes;
export const selectRoute = (path) => (state) =>
  state.app.routes.find((route) => route.path === path);
export const selectRouteConfig = (path) => (state) =>
  selectRoute(path)(state).config;

export default appSlice.reducer;
