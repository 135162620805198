import React from 'react';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import { useApi } from '../../../hooks/useApi';

const Steps = ({ value }) => {
  const { useLazyGetWhitelabelQuery } = useApi();
  const [getWhitelabel, { data, error, isLoading }] =
    useLazyGetWhitelabelQuery();

  React.useEffect(() => {
    getWhitelabel('winston.services');
  }, [getWhitelabel]);

  if (isLoading) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="body1">Error loading data</Typography>;
  }

  switch (value) {
    case 1:
      return (
        <>
          <Typography variant="h4">Link Your Profile</Typography>
          <Typography variant="body1">
            Link your profile to your existing social media accounts.
          </Typography>
        </>
      );
    case 2:
      return (
        <>
          <Typography variant="h4">Register for our newsletter</Typography>
          <Typography variant="body1">
            Join our growing community of blockchain enthusiasts.
          </Typography>
        </>
      );
    case 3:
      return (
        <>
          <Typography variant="h4">Complete your profile</Typography>
          <Typography variant="body1">
            Complete your profile to start using Winston.
          </Typography>
        </>
      );
    default:
      return null;
  }
};
// ToDo: Map form validation schema to config.
const template = [
  {
    component: 'Box',
    props: {
      sx: {
        background: 'linear-gradient(135deg, #2d2169, #2d2169)',
        margin: 0,
        padding: 0,
      },
    },
    children: [
      {
        component: 'Container',
        props: {
          maxWidth: 'xl',
        },
        children: [
          {
            component: 'Box',
            props: {
              sx: {
                paddingTop: '50px',
              },
            },
            content: null,
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                backgroundImage: 'url(/assets/image.png)',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: 'calc(100vh - 100px)',
                width: '100%',
                flex: 1,
                flexDirection: 'column',
              },
            },
            content: null,
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingBottom: '50px',
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                overflow: 'hidden',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    width: '50%', // Assuming 17px as a common scrollbar width
                    overflowY: 'auto',
                    height: '100%',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                  },
                },
                children: [
                  {
                    component: 'Form',
                    props: {
                      initialValues: {
                        title: '',
                        firstName: '',
                        middleName: '',
                        lastName: '',
                        email: '',
                        country: '',
                        postalCode: '',
                        state: '',
                        city: '',
                        address1: '',
                        address2: '',
                      },
                      validationSchema: yup.object({
                        title: yup.string().required('Title is required'),
                        firstName: yup
                          .string()
                          .required('First Name is required'),
                        middleName: yup
                          .string()
                          .required('Middle Name is required'),
                        lastName: yup
                          .string()
                          .required('Last Name is required'),
                        email: yup
                          .string()
                          .email('Invalid email')
                          .required('Email is required'),
                        country: yup.string().required('Country is required'),
                        postalCode: yup
                          .string()
                          .required('Postal Code is required'),
                        state: yup.string().required('State is required'),
                        city: yup.string().required('City is required'),
                        address1: yup
                          .string()
                          .required('Address Line 1 is required'),
                        address2: yup
                          .string()
                          .required('Address Line 2 is required'),
                      }),
                    },
                    action: {
                      method: 'useForm',
                      type: 'fetch',
                    },
                    onSubmit: {
                      url: 'https://winston.services/api/v1',
                      onSuccess: {
                        message: 'Form submitted successfully',
                        variant: 'success',
                        redirect: '/',
                      },
                      onError: {
                        message: 'Form submission failed',
                        variant: 'error',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h2',
                          sx: { fontFamily: 'Cookie', color: 'white' },
                        },
                        content: 'Register for our newsletter',
                      },
                      {
                        component: 'Box',
                        props: {
                          display: 'flex',
                          flexDirection: 'column',
                          marginY: 1,
                          gap: 1,
                        },
                        children: [
                          {
                            component: 'NameSection',
                            props: {
                              sx: {
                                '& .MuiInputLabel-root': {
                                  color: 'white',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'white',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    borderColor: 'white',
                                  },
                                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                  {
                                    border: '1.5px solid #f5f5f5',
                                  },
                                '& .MuiInputBase-input': {
                                  color: 'white',
                                },
                              },
                              InputLabelProps: {
                                style: { color: 'white' },
                              },
                              InputProps: {
                                style: { color: 'white' },
                                classes: {
                                  notchedOutline: {
                                    borderColor: 'white',
                                  },
                                },
                              },
                            },
                            content: null,
                          },
                        ],
                      },
                      {
                        component: 'Box',
                        props: {
                          display: 'flex',
                          flexDirection: 'column',
                          marginY: 1,
                        },
                        children: [
                          {
                            component: 'EmailTextField',
                            props: {
                              InputLabelProps: {
                                style: { color: 'white' },
                              },
                              InputProps: {
                                style: { color: 'white' },
                                classes: {
                                  notchedOutline: {
                                    borderColor: 'white',
                                  },
                                },
                              },
                            },
                            content: null,
                          },
                        ],
                      },
                      {
                        component: 'AddressSection',
                        props: {
                          InputLabelProps: {
                            style: { color: 'white' },
                          },
                          InputProps: {
                            style: { color: 'white' },
                            classes: {
                              notchedOutline: {
                                borderColor: 'white',
                              },
                            },
                          },
                        },
                        content: null,
                      },
                      {
                        component: 'Button',
                        props: {
                          variant: 'contained',
                          type: 'submit',
                          fullWidth: true,
                        },
                        content: 'Submit',
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            component: 'Box',
            props: {
              sx: {
                paddingBottom: '50px',
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    width: '50%',
                  },
                },
                children: [
                  {
                    component: 'Form',
                    props: {
                      initialValues: {
                        chainId: '',
                        token: '',
                      },
                      validationSchema: yup.object({
                        chainId: yup.string().required('Chain ID is required'),
                        token: yup.string().required('Token is required'),
                      }),
                    },
                    action: {
                      method: 'useForm',
                      type: 'tokenGate',
                      options: {
                        amount: 1,
                      },
                    },
                    onSubmit: {
                      url: 'https://winston.services/api/v1',
                      onSuccess: {
                        message: 'Form submitted successfully',
                        variant: 'success',
                        redirect: '/',
                      },
                      onError: {
                        message: 'Form submission failed',
                        variant: 'error',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h2',
                          sx: { fontFamily: 'Cookie', color: 'white' },
                        },
                        content: 'Tokengate',
                      },
                      {
                        component: 'Box',
                        props: {
                          display: 'flex',
                          flexDirection: 'column',
                          marginY: 1,
                        },
                        children: [
                          {
                            component: 'TokenGateSection',
                            props: {
                              data: [
                                {
                                  chainId: 1,
                                  chainName: 'Ethereum',
                                  address:
                                    '0x0ff80a1708191c0da8aa600fa487f7ac81d7818c',
                                  name: 'Rickle - ETH',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0xeca15e1bbff172d545dd6325f3bae7b737906737',
                                  name: 'Rickle - BSC',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x75578ebBefe274F240B8E1b5859cA34f342157D9',
                                  name: 'Winston',
                                  symbol: 'WIN',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x3A81caafeeDCF2D743Be893858cDa5AcDBF88c11',
                                  name: 'Ahwa',
                                  symbol: 'AHWA',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x181d5cec845973e64bccf9848412948be8a3883e',
                                  name: 'Winston Academy Coin',
                                  symbol: 'WAC',
                                  decimals: 18,
                                },
                                {
                                  chainId: 137,
                                  chainName: 'Polygon',
                                  address:
                                    '0x9fdc23fe295104ac55fef09363c56451d0e37cfa',
                                  name: 'Rickle - Polygon',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 42161,
                                  chainName: 'Arbitrum',
                                  address:
                                    '0x2D0E0Ec9c82C67C4A8d7c7E6c176831f52821B33',
                                  name: 'Rickle - Arbitrum',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 100,
                                  chainName: 'Gnosis',
                                  address:
                                    '0x2dF5912439d2D14d04a7742346508505288eF367',
                                  name: 'Rickle - Gnosis',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                              ],
                            },
                            content: null,
                          },
                        ],
                      },
                      {
                        component: 'Button',
                        props: {
                          variant: 'contained',
                          type: 'submit',
                          fullWidth: true,
                        },
                        content: 'Submit',
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            component: 'Box',
            props: {
              sx: {
                paddingBottom: '50px',
              },
            },
            children: [],
          },
          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    width: '50%',
                  },
                },
                children: [
                  {
                    component: 'Form',
                    props: {
                      initialValues: {
                        chainId: '',
                        token: '',
                      },
                      validationSchema: yup.object({
                        chainId: yup.string().required('Chain ID is required'),
                        token: yup.string().required('Token is required'),
                      }),
                    },
                    action: {
                      method: 'useForm',
                      type: 'tokenIconUpdate',
                    },
                    onSubmit: {
                      url: 'https://winston.services/api/v1',
                      onSuccess: {
                        message: 'Form submitted successfully',
                        variant: 'success',
                        redirect: '/',
                      },
                      onError: {
                        message: 'Form submission failed',
                        variant: 'error',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: {
                          variant: 'h2',
                          sx: { fontFamily: 'Cookie', color: 'white' },
                        },
                        content: 'Token Icon Update',
                      },
                      {
                        component: 'Box',
                        props: {
                          display: 'flex',
                          flexDirection: 'column',
                          marginY: 1,
                        },
                        children: [
                          {
                            component: 'TokenIconUpdateSection',
                            props: {
                              data: [
                                {
                                  chainId: 1,
                                  chainName: 'Ethereum',
                                  address:
                                    '0x0ff80a1708191c0da8aa600fa487f7ac81d7818c',
                                  name: 'Rickle - ETH',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0xeca15e1bbff172d545dd6325f3bae7b737906737',
                                  name: 'Rickle - BSC',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x75578ebBefe274F240B8E1b5859cA34f342157D9',
                                  name: 'Winston',
                                  symbol: 'WIN',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x3A81caafeeDCF2D743Be893858cDa5AcDBF88c11',
                                  name: 'Ahwa',
                                  symbol: 'AHWA',
                                  decimals: 18,
                                },
                                {
                                  chainId: 56,
                                  chainName: 'Binance Smart Chain',
                                  address:
                                    '0x181d5cec845973e64bccf9848412948be8a3883e',
                                  name: 'Winston Academy Coin',
                                  symbol: 'WAC',
                                  decimals: 18,
                                },
                                {
                                  chainId: 137,
                                  chainName: 'Polygon',
                                  address:
                                    '0x9fdc23fe295104ac55fef09363c56451d0e37cfa',
                                  name: 'Rickle - Polygon',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 42161,
                                  chainName: 'Arbitrum',
                                  address:
                                    '0x2D0E0Ec9c82C67C4A8d7c7E6c176831f52821B33',
                                  name: 'Rickle - Arbitrum',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                                {
                                  chainId: 100,
                                  chainName: 'Gnosis',
                                  address:
                                    '0x2dF5912439d2D14d04a7742346508505288eF367',
                                  name: 'Rickle - Gnosis',
                                  symbol: 'rkl',
                                  decimals: 18,
                                },
                              ],
                            },
                            content: null,
                          },
                        ],
                      },
                      {
                        component: 'Button',
                        props: {
                          variant: 'contained',
                          type: 'submit',
                          fullWidth: true,
                        },
                        content: 'Submit',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            component: 'Box',
            props: {
              sx: {
                paddingBottom: '50px',
              },
            },
            children: [],
          },

          {
            component: 'Box',
            props: {
              sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              },
            },
            children: [
              {
                component: 'Box',
                props: {
                  display: 'flex',
                  flexDirection: 'column',
                  marginY: 1,
                  gap: 1,
                },
                children: [
                  {
                    component: 'Typography',
                    props: {
                      variant: 'h2',
                      sx: { fontFamily: 'Cookie', color: 'white' },
                    },
                    content: 'OAuth',
                  },
                  {
                    component: 'Box',
                    props: {
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      marginY: 1,
                      gap: 1,
                    },
                    children: [
                      {
                        component: 'Button',
                        props: {
                          variant: 'contained',
                          type: 'button',
                          fullWidth: true,
                          sx: { textTransform: 'none' },
                          href: `https://discord.com/oauth2/authorize?client_id=594415583638847488&response_type=code&redirect_uri=${encodeURIComponent('https://winston.services/link')}&scope=identify`,
                        },
                        content: 'OAuth With Discord',
                      },                      
                    ],
                  },
                ],
              },
            ],
          },
          {
            component: 'Footer',
            props: {},
            children: [
              {
                component: 'Box',
                props: {
                  sx: {
                    display: 'flex',
                    gap: 2,
                  },
                },
                children: [
                  {
                    component: 'Box',
                    props: {
                      sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      },
                    },
                    children: [
                      {
                        component: 'Typography',
                        props: { variant: 'body1', color: 'white' },
                        content:
                          '© 2024 Winston Services. All rights reserved.',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default template;
