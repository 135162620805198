import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  hidden: true,
  direction: 'left',
  actions: [],
};

export const speeddialSlice = createSlice({
  name: 'speeddial',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setHidden: (state, action) => {
      state.hidden = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
    setActions: (state, action) => {
      state.actions = action.payload;
    },
  },
});

export const { setOpen, setHidden, setDirection, setActions } =
  speeddialSlice.actions;

export const selectSpeeddial = (state) => state.speeddial;

export default speeddialSlice.reducer;
