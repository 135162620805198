import React, { StrictMode } from 'react';

import AppProvider  from './provider';

const App = () => (
  <StrictMode>
    <AppProvider />
  </StrictMode>
);

export default App;
