import React from 'react';
import AuthLayout from './AuthLayout';
import LandingLayout from './LandingLayout';
import DashboardLayout from './DashboardLayout';
export { AuthLayout, LandingLayout, DashboardLayout };
export default {
  AuthLayout: <AuthLayout />,
  LandingLayout: <LandingLayout />,
  DashboardLayout: <DashboardLayout />,
};
