import React, { useState, useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import AuthRedirect from './AuthRedirect';
import { useAppRoutes } from '../../hooks/useAppRoutes';
import ScrollToTop from '../../components/common/scroll-to-top';
import { SnackbarProvider } from 'notistack';
import AppModalProvider from './AppModalProvider';
import Layouts from '../../layouts';
import Pages from '../../pages';
import AppFallback from '../../components/common/app-fallback';
import SpeedDial from '../../components/common/speed-dial';
import Drawer from '../../components/common/drawer';
 
const getRouteWrapper = (component, authRoute = true, layout = true) => {
  return (
    <AuthRedirect authenticatedRoute={authRoute}>
      <React.Suspense fallback={<AppFallback />}>
        {layout ? Layouts[component] : Pages[component]}
      </React.Suspense>
    </AuthRedirect>
  );
};

const mapRoutes = (routes) => {
  // console.log('routes', routes);
  return routes.map((route) => ({
    path: route.path,
    element: getRouteWrapper(route.element, route.requiredAuth, true),
    children: route.children.map((child) => ({
      index: child.index,
      path: child.path,
      element: getRouteWrapper(
        child.element.component,
        child.element.requiredAuth,
        false
      ),
    })),
  }));
};

const AppRoutes = () => {
  const { routes } = useAppRoutes();
  const [loadingRoutes, setLoadingRoutes] = useState(true);
  const [activeRoutes, setActiveRoutes] = useState(mapRoutes(routes));

  const LoadRoutes = React.useCallback(() => {
    if (loadingRoutes) {
      const _routes = mapRoutes(routes);
      // console.log('routes', _routes);
      _routes.push({
        name: 'NotFound',
        path: '*',
        element: 'LandingLayout',
        children: [
          {
            index: true,
            path: '*',
            element: { component: 'NotFound', requiredAuth: false },
          },
        ],
        requiredAuth: false,
        key: 'not-found',
      });
      setActiveRoutes(_routes);
      setLoadingRoutes(false);
    }
  }, [routes, loadingRoutes]);

  useEffect(() => {
    LoadRoutes();
  }, [LoadRoutes]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
        <ScrollToTop />
        <React.Suspense fallback={<AppFallback />}>
          {useRoutes(activeRoutes)}
        </React.Suspense>
        <Drawer />
        <AppModalProvider />
        <SpeedDial />
    </SnackbarProvider>
  );
};

const RoutesProvider = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export { RoutesProvider as default, RoutesProvider };
