import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
const AppFallback = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default AppFallback;
