import { combineReducers, configureStore } from '@reduxjs/toolkit';
import apiInstance from './createApiInstance';

import appReducer from './app';
import i18nReducer from './i18n';
import speeddialReducer from './speeddial';

const rootReducer = combineReducers({
  app: appReducer,
  i18n: i18nReducer,
  speeddial: speeddialReducer,
  [apiInstance.reducerPath]: apiInstance.reducer,
  });
  
  const reducer = (state, action) => {
    if (action.type === 'logout') {
      state = undefined;
    }
    return rootReducer(state, action);
  };
  
  export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        apiInstance.middleware
      ),
  });
  